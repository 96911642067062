import React from 'react'
import { Link } from 'gatsby'
import gatsbyConfig from '../../gatsby-config'

const MenuItem = ({ to, children }: { to: string; children: any }) => (
  <Link to={to} className=" font-semibold tracking-tight no-underline pr-2">
    {children}
  </Link>
)
export default (props) => (
  <div
    {...props}
    className="flex pb-2 md:justify-between md:items-end flex-col md:flex-row"
  >
    <Link
      className="tracking-tight no-underline font-semibold text-3xl text-gray-800 pr-2"
      to={'/'}
    >
      {gatsbyConfig.siteMetadata.shortTitle}{' '}
      <span className="text-base text-gray-600">by Romain Pechayre</span>
    </Link>{' '}
    <div>
      <MenuItem to={'/books'}>Books</MenuItem>
      <MenuItem to={'/podcasts'}>Podcasts</MenuItem>
      <MenuItem to={'/about'}>About</MenuItem>
    </div>
  </div>
)
