import React from 'react'

import { Helmet } from 'react-helmet'
import SideBar from './SideBar'
import Menu from './Menu'
import gatsbyConfig from '../../gatsby-config'
import '../styles/global.css'

export default function Layout({ children }) {
  const title = `${gatsbyConfig.siteMetadata.shortTitle} — Romain Pechayre`
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href={gatsbyConfig.siteMetadata.siteUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta name="twitter:creator" content="@Romain" />
        <meta name="twitter:site" content="@rpechayr" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
      </Helmet>
      <div className="flex md:items-center flex-col py-2 px-4">
        <div>
          <div className="flex flex-col md:flex-row">
            <div className="flex flex-col max-w-prose md:w-4/5 md:mx-3">
              <Menu />
              {children}
            </div>
            <SideBar className="w-full md:w-1/5 py-3" />
          </div>
        </div>
      </div>
    </div>
  )
}
