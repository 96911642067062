import { Link } from 'gatsby'
import React from 'react'

export default (props) => {
  return (
    <div {...props}>
      <div className="text-sm bg-post-it shadow-md h-auto md:mt-10 py-2 px-3 rounded-md md:fixed md:w-1/5 shadow-sm text-gray-700">
        <p>
          🧑‍💻{'\u00A0'}
          {'\u00A0'}I'm <Link to="/about">Romain</Link>, software engineer, ex
          co-founder and CTO at <a href="https://www.yeeld.com/">Yeeld</a> and{' '}
          <a href="https://www.eventmaker.com/">Eventmaker</a>.
        </p>
        <p>
          You may be interested in <Link to="/ways-i-help">ways I help</Link> or
          simply want to follow me on{' '}
          <Link to="https://twitter.com/rpechayr">Twitter</Link> /{' '}
          <Link to="https://www.linkedin.com/in/romainpechayre/">LinkedIn</Link>{' '}
          / <Link to="https://github.com/rpechayr">Github</Link>.
        </p>
      </div>
    </div>
  )
}
